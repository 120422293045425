import { link } from "@markdoc/next.js/tags";

import DemoKey from "../components/DemoKey";
import MapTilesGrid from "../components/MapTilesGrid";
import RoutingDemo from "../components/RoutingDemo";
import PricingPricesTable from "../components/PricingPricesTable"
import PricingServicesTable from "../components/PricingServicesTable"

const tags = {
  "demo-key": {
    render: DemoKey,
    attributes: {
      prefix: { type: String },
    },
  },
  "map-tiles-grid": { render: MapTilesGrid },
  "routing-demo": { render: RoutingDemo },
  "pricing-prices-table": { render: PricingPricesTable },
  "pricing-services-table": { render: PricingServicesTable },
  link,
};

export default tags;
