import Head from "next/head";
import { useRouter } from "next/router";

import CardsGridApis from "./CardsGridApis";
import ContainerWithSidebar from "./ContainerWithSidebar";
import Layout from "./Layout";
import PageHero from "./PageHero";
import PageIntro from "./PageIntro";
import Swagger from "./Swagger";

import type { ApiFrontmatter } from "../types";
import CreditsCalculator from "./CreditsCalculator";

type Props = {
  apis: ApiFrontmatter[];
  children: React.ReactNode;
  showApis?: "bottom" | "top";
  showPageHero?: boolean;
  showPageCostCalculator?: boolean;
  sidebar?: "licensing" | "help" | false;
  swagger?: string;
  title?: string;
  description?: string;
};

export default function Document(props: Props) {
  const router = useRouter();
  const title = props.title
    ? `${props.title} | geOps Developer Portal`
    : "geOps Developer Portal";
  const description =
    props.description || "Welcome to the geOps Developer Portal";
  const image = "https://developer.geops.io/img/hero.webp";
  return (
    <Layout apis={props.apis}>
      <Head>
        {title && <title>{title}</title>}
        {description && <meta name="description" content={description} />}
        <link
          rel="canonical"
          href={`https://developer.geops.io${router.asPath}`}
        />
        {/* OpenGraph meta tags */}
        {title && <meta property="og:title" content={title} />}
        <meta
          property="og:url"
          content={`https://developer.geops.io${router.asPath}`}
        />
        <meta property="og:image" content={image} />
        <meta property="og:site_name" content="geOps" />
        {description && (
          <meta property="og:description" content={description} />
        )}
        {/* Twitter meta tags */}
        {title && <meta name="twitter:title" content={title} />}
        {description && (
          <meta name="twitter:description" content={description} />
        )}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@geOps" />
        <meta name="twitter:creator" content="@geOps" />
        <meta name="twitter:image:src" content={image} />
      </Head>
      {props.showPageHero ? (
        <PageHero />
      ) : props.title ? (
        <PageIntro title={props.title} />
      ) : null}
      {props.showPageCostCalculator ? <CreditsCalculator /> : null}
      {props.showApis === "top" && <CardsGridApis apis={props.apis} />}
      {props.sidebar !== false && (
        <ContainerWithSidebar sidebar={props.sidebar}>
          <div className="prose prose-headings:text-blue-800 prose-a:font-bold prose-a:text-green xl:prose-lg">
            {props.children}
          </div>
        </ContainerWithSidebar>
      )}

      {props.swagger && <Swagger url={props.swagger} />}
      {props.showApis === "bottom" && (
        <CardsGridApis apis={props.apis} withBackground />
      )}
    </Layout>
  );
}
