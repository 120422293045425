import useDemoKey from "../utils/useDemoKey";

function Item({ image }: { image: string }) {
  return <img alt="" src={image} className="aspect-square w-96" />;
}

function Row(props: { children: React.ReactNode[]; href: string }) {
  const key = useDemoKey();
  return (
    <>
      {props.children.map((item, index) => (
        <a key={index} href={`${props.href}?key=${key || ""}`}>
          {item}
        </a>
      ))}
    </>
  );
}

export default function MapTilesGrid() {
  return (
    <div className="not-prose grid grid-cols-2 gap-2">
      <Row href="https://maps.geops.io/styles/travic_v2/">
        <Item image="/img/maps/map_travic_gen.png" />
        <Item image="/img/maps/map_travic.png" />
      </Row>
      <Row href="https://maps.geops.io/styles/base_bright_v2/">
        <Item image="/img/maps/map_base_gen.png" />
        <Item image="/img/maps/map_base.png" />
      </Row>
      <Row href="https://maps.geops.io/styles/base_dark_v2/">
        <Item image="/img/maps/map_dark_gen.png" />
        <Item image="/img/maps/map_dark.png" />
      </Row>
    </div>
  );
}
