import Link from "next/link";

interface ItemProps {
  description: string;
  href: string;
  image: string;
  title: string;
}

function Item({ description, href, image, title }: ItemProps) {
  return (
    <li>
      <Link href={href} className="flex h-full flex-col bg-white shadow-lg shadow-gray-500/20 transition hover:scale-107 hover:shadow-xl focus-visible:outline-none focus-visible:ring focus-visible:ring-blue-400">
          <img
            src={image}
            alt={description}
            width="264"
            height="160"
            className="w-full"
            loading="lazy"
          />
          <div className="flex flex-col gap-y-1 p-4">
            <h3 className="text-lg font-bold text-blue-800">{title}</h3>
            <p className="text-sm text-gray-600">{description}</p>
          </div>
      </Link>
    </li>
  );
}

interface CardsGridProps {
  children: React.ReactElement<ItemProps>[];
  title: string;
  withBackground?: boolean;
}

function CardsGrid({ children, title, withBackground }: CardsGridProps) {
  return (
    <div
      className={`mt-12 lg:mt-20 ${withBackground ? "bg-gray-50 py-8" : ""}`}
    >
      <div className="fluid-container">
        <h2 className="text-xl font-bold text-blue-800 md:text-3xl">{title}</h2>
        <ul className="mt-4 grid gap-6 xs:grid-cols-2 md:mt-6 lg:grid-cols-4 ">
          {children}
        </ul>
      </div>
    </div>
  );
}

CardsGrid.Item = Item;

export default CardsGrid;
