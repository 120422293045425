import { Tag, nodes } from "@markdoc/markdoc";

import Document from "../../components/Document";
import getApisFrontmatter from "../../utils/getApisFrontmatter";

const apis = getApisFrontmatter();

const document = {
  ...nodes.document,
  render: Document,
  transform(node, config) {
    const attributes = { apis, ...config.variables.markdoc.frontmatter };
    return new Tag(this.render, attributes, node.transformChildren(config));
  },
};

export default document;
