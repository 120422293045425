import type { ApiFrontmatter } from "../types";

import CardsGrid from "./CardsGrid";

interface Props {
  apis: ApiFrontmatter[];
  withBackground?: boolean;
}

export default function CardsGridApis({ apis, withBackground }: Props) {
  return (
    <div className={withBackground ? "-mb-12" : ""} data-test="cards-grid-apis">
      <CardsGrid title="Explore our APIs" withBackground={withBackground}>
        {apis.map((api) => (
          <CardsGrid.Item
            key={api.slug}
            description={api.description}
            href={`/apis/${api.slug}`}
            image={`/img/cards/apis/${api.slug}.webp`}
            title={api.title}
          />
        ))}
      </CardsGrid>
    </div>
  );
}
