import { parse } from "@markdoc/markdoc";
import { readFileSync, readdirSync } from "fs";
import { load } from "js-yaml";
import { join } from "path";

let apis: unknown[] = [];

export default function getApisFrontmatter() {
  if (readdirSync && apis.length === 0) {
    const pagesPath = join(process.cwd(), "pages/apis");
    apis = readdirSync(pagesPath)
      .filter((filePath) => /\.md?$/.test(filePath))
      .map((filePath) => {
        const source = readFileSync(join(pagesPath, filePath));
        const ast = parse(source.toString());
        const frontmatter = load(ast.attributes.frontmatter);
        const slug = filePath.replace(/\.md?$/, "");
        return Object.assign({ slug, weight: 0 }, frontmatter);
      })
      .sort((a, b) => a.weight - b.weight);
  }
  return apis;
}
