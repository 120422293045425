import Table from "./Table";

const plansPrices = [
  { service: 'Maps Standard', unit: '1000 requests for vector tiles', credits: '1' },
  { service: 'Maps Raster', unit: '1000 requests for raster map tiles', credits: '10' },
  { service: 'Routing', unit: '1000 routing requests', credits: '25' },
  { service: 'Stops', unit: '1000 search requests for stops', credits: '10' },
  { service: 'Realtime via Get', unit: '1000 requests for vehicle position, stop times', credits: '5' },
  { service: 'Realtime via Websocket', unit: '1000 minutes of open websocket connection', credits: '50' },
]

type TableRowProps = {
  service: string | React.ReactNode,
  unit: string | React.ReactNode,
  credits: string | React.ReactNode,
}

function TableRow({ service, credits, unit }: TableRowProps) {
  return (
    <Table.Row>
      {[service, unit, credits].map((value, index) => {
        return (
          <Table.Cell
            key={`${value}-${index}`}
            className={`max-w-0 py-4 px-3 align-top text-sm font-bold text-gray-500 sm:w-auto sm:max-w-none${index === 2 ? ' text-right' : ''}`}
          >
            {value}
          </Table.Cell>
        )
      })}    
    </Table.Row>
  )
}

export default function PricingServicesTable() {
  return (
    <div className="mt-12 space-y-8 lg:mt-20 not-prose">
      <Table data-cy="pricing-services-table">
        <Table.Head>
          <Table.HeadCell>Service</Table.HeadCell>
          <Table.HeadCell>Unit</Table.HeadCell>
          <Table.HeadCell className="text-right">Credits</Table.HeadCell>
        </Table.Head>
        <Table.Body>
          {plansPrices.map((item, index) => {
            return (
              <TableRow
                key={`${item}-${index}`}
                service={item.service}
                credits={item.credits}
                unit={item.unit}
              />
            )
          })}
        </Table.Body>
      </Table>
    </div>
  );
}
