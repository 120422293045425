import { useCallback, useEffect, useState, useRef } from "react";
import debounce from "lodash.debounce";
import type {  DebounceSettings } from "lodash";

export function useDebounce<T>(
  value: T,
  delay: number = 0,
  options?: DebounceSettings
): T {
  const previousValue = useRef(value);
  const [current, setCurrent] = useState(value);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCallback = useCallback(
    debounce((value: T) => setCurrent(value), delay, options),
    [value, delay, options]
  );

  useEffect(() => {
    // doesn't trigger the debounce timer initially
    if (value !== previousValue.current) {
      debouncedCallback(value);
      previousValue.current = value;
      // cancel the debounced callback on clean up
      return debouncedCallback.cancel;
    }
  }, [debouncedCallback, value]);

  return current;
}
