declare interface Props extends React.HTMLProps<HTMLInputElement> {
  appendClassName?: string;
}

export default function InputLabel({
  appendClassName,
  children,
  ...props
}: Props) {
  let className = "text-sm font-bold text-blue-700 m-3";

  if (appendClassName) {
    className += ` ${appendClassName}`;
  }

  return (
    <div className={className} {...props}>
      {children}
    </div>
  );
}
