import { TableHTMLAttributes } from "react";

function Table({
  children,
  ...props
}: React.PropsWithChildren & TableHTMLAttributes<HTMLTableElement>) {
  return (
    <div className="rounded-sm ring-1 ring-blue-100">
      <table className="min-w-full divide-y divide-blue-100" {...props}>
        {children}
      </table>
    </div>
  );
}

Table.Head = function Head({ children }: React.PropsWithChildren) {
  return (
    <thead className="sticky top-0 z-10 bg-blue-50">
      <tr>{children}</tr>
    </thead>
  );
};

Table.HeadCell = function HeadCell({
  children,
  className = ""
}: React.PropsWithChildren & React.TdHTMLAttributes<HTMLTableCellElement>) {
  return (
    <th className={`px-3 py-3.5 text-left text-sm font-bold uppercase text-blue-800 align-middle ${className}`}>
      {children}
    </th>
  );
};

Table.Body = function Body({ children }: React.PropsWithChildren) {
  return (
    <tbody className="divide-y divide-blue-100 bg-white">{children}</tbody>
  );
};

Table.Row = function Row({ children }: React.PropsWithChildren) {
  return <tr className="group">{children}</tr>;
};

Table.Cell = function Cell({
  className = "",
  children,
  ...props
}: React.PropsWithChildren & React.TdHTMLAttributes<HTMLTableCellElement>) {
  return (
    <td
      className={`px-3 py-4 text-sm text-gray-500 group-hover:bg-blue-50 ${className}`}
      {...props}
    >
      {children}
    </td>
  );
};

export default Table;
