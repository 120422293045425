import { ComponentType } from "react";
import { Switch } from "@headlessui/react";

type ExtractProps<T> = T extends ComponentType<infer P> ? P : T;

export default function SwitchInput({
  ...props
}: ExtractProps<typeof Switch>) {
  const className = `${
    props.checked ? "bg-blue-600" : "bg-gray-200"
  } relative inline-flex h-6 w-11 items-center rounded-full`;

  return (
    <Switch className={className} {...props}>
      <span
        className={`${
          props.checked ? "translate-x-6" : "translate-x-1"
        } inline-block h-4 w-4 transform rounded-full bg-white`}
      />
    </Switch>
  );
}
