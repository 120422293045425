import dynamic from "next/dynamic";

import "swagger-ui-react/swagger-ui.css";

// @ts-ignore
const SwaggerUI = dynamic(() => import("swagger-ui-react"), { ssr: false });

export default function Swagger({ url }: { url?: string }) {
  return url ? (
    <div className="fluid-container">
      <SwaggerUI docExpansion="full" url={url} />
    </div>
  ) : null;
}
