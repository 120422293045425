import Link from "next/link";

export default function PageHero() {
  return (
    <div>
      <div className="sticky top-0 w-full overflow-hidden ">
        <img
          src="/img/hero.webp"
          alt="Photo of a motion=blurred tunnel"
          className="absolute mx-auto block max-h-[680px] w-full scale-125 object-cover blur-lg"
        />
        <img
          src="/img/hero.webp"
          alt="Photo of a motion=blurred tunnel"
          className="relative mx-auto block max-h-[680px] w-full max-w-[1440px] object-cover"
        />
      </div>
      <div className="fluid-container relative sm:-mt-20 md:-mt-32 lg:-mt-64">
        <div className="-mx-4 max-w-3xl bg-white px-4 pt-6 sm:w-4/5 md:-mx-8 md:px-8 lg:-mx-16 lg:w-2/3 lg:px-16 lg:pt-12">
          <h1 className="max-w-2xl text-3xl font-black text-blue-800 md:text-4xl lg:text-5xl">
            Your Toolbox for Mobility and Logistics
          </h1>
          <p className="prose mt-4 prose-headings:text-blue-800 lg:mt-6 xl:prose-lg">
            The geOps developer portal provides a toolbox for public transit applications. The tools comprise web services as well as open source libraries that have been inspired by many projects realized for public transit agencies.
          </p>

          <Link href="/guides" className="mt-4 inline-flex items-center whitespace-nowrap rounded-full bg-green px-6 py-3 text-base font-bold uppercase text-white transition hover:bg-green-400 focus-visible:outline-none focus-visible:ring focus-visible:ring-green-600 lg:mt-6">
              Learn more
          </Link>
        </div>
      </div>
    </div>
  );
}
