import { useEffect, useState, useCallback, useMemo } from "react";
import { Map, StyleSpecification, NavigationControl } from "maplibre-gl";
import Input from "./Input";
import { CopyrightControl, RealtimeLayer } from "mobility-toolbox-js/mapbox";
import { useDebounce } from "../utils/useDebounce";
import Switch from "./Switch";
import InputLabel from "./InputLabel";

// A bit hackish, but it works.
const avoidRoundingProblem = (cpt: number) => {
  return Math.round(cpt * 1000000) / 1000000;
};

const apiKey = process.env.NEXT_PUBLIC_API_KEY;
let nbTilesRequested = 0;

export default function CreditsCalculator() {
  const [map, setMap] = useState<Map>();
  const [container, setContainer] = useState<HTMLDivElement>();
  const [width, setWidth] = useState(800);
  const [height, setHeight] = useState(600);
  const [cptTileRequests, setCptTileRequests] = useState(0);
  const [cptRealtimeMinutes, setCptRealtimeMinutes] = useState(0);
  const [style, setStyle] = useState<string | StyleSpecification>();
  const [useRaster, setUseRaster] = useState(false);
  const [useRealtime, setUseRealtime] = useState(false);
  const [maxWidth, setMaxWidth] = useState(1000);
  // const [
  //   maxHeight,
  //   // setMaxHeight
  // ] = useState(10000);
  // const router = useRouter();

  // Reload the map when the width and height changes.
  const debouncedWidth = useDebounce(width, 500);
  const debouncedHeight = useDebounce(height, 500);

  useEffect(() => {
    if (!container || !style) {
      return;
    }
    nbTilesRequested = 0;
    const copyrights = new CopyrightControl({});
    const mapp = new Map({
      style,
      container,
      center: [7.842609, 47.997791], // starting position [lng, lat]
      zoom: 6,
      attributionControl: false,
      pitchWithRotate: false,
      transformRequest: (url, resourceType) => {
        if (resourceType === "Tile") {
          nbTilesRequested = nbTilesRequested + 1;
          setCptTileRequests(parseInt(nbTilesRequested.toFixed(0), 10));
        }
        return { url };
      },
    });
    mapp.showTileBoundaries = false;
    mapp.addControl(new NavigationControl({}), "top-right");
    copyrights.attachToMap(mapp);
    setMap(mapp);
    return () => {
      copyrights.detachFromMap();
      mapp.remove();
    };
  }, [container, style, debouncedWidth, debouncedHeight]);

  useEffect(() => {
    if (map) {
      map.resize();
    }
  }, [map, width, height]);

  useEffect(() => {
    nbTilesRequested = 0;
    setCptTileRequests(0);
    const styleName = "travic_v2";
    const prefix = "https://maps.geops.io/styles/" + styleName;
    const suffix = ".json?key=" + apiKey;
    let style: string | StyleSpecification = prefix + "/style" + suffix;

    if (useRaster) {
      style = {
        name: styleName,
        version: 8,
        sources: {
          [styleName]: {
            type: "raster",
            url: prefix + suffix,
            tileSize: 256,
          },
        },
        layers: [
          {
            id: styleName,
            source: styleName,
            type: "raster",
          },
        ],
      };
    }
    setStyle(style);
  }, [useRaster]);

  useEffect(() => {
    let realtime: RealtimeLayer | null;
    let intervalId: number;
    let cptSec = 0;

    const startCounting = () => {
      clearInterval(intervalId);
      intervalId = window.setInterval(() => {
        cptSec += 1;
        const urlSearchParams = new URLSearchParams(window.location.search);
        
        if (urlSearchParams.get("debug")) {
          setCptRealtimeMinutes(cptSec);
        } else {
          setCptRealtimeMinutes(Math.ceil(cptSec / 60));
        }
      }, 1000);
    };

    const stopCounting = () => {
      clearInterval(intervalId);
    };

    if (map && useRealtime) {
      realtime = new RealtimeLayer({
        apiKey,
        userInteractions: false,
        onStart: startCounting,
        onStop: stopCounting,
      });

      setCptRealtimeMinutes(1)
      realtime.attachToMap(map, "");
    }

    return () => {
      clearInterval(intervalId);
      setCptRealtimeMinutes(0);
      if (realtime && map && map.style) {
        realtime.detachFromMap();
        realtime = null;
      }
    };
  }, [useRealtime, map]);

  const verifySize = useCallback(() => {
    const parentWidth = container?.parentElement?.clientWidth || 1000;
    if (parentWidth && width > parentWidth) {
      setWidth(parentWidth);
    }
    setMaxWidth(parentWidth);
  }, [container, width]);

  useEffect(() => {
    window.addEventListener("resize", verifySize);
    return () => {
      window.removeEventListener("resize", verifySize);
    };
  }, [verifySize]);

  const mapsCredits = useMemo(() => {
    return avoidRoundingProblem(cptTileRequests * (useRaster ? 10 : 1));
  }, [cptTileRequests, useRaster]);

  const realtimeCredits = useMemo(() => {
    console.log(cptRealtimeMinutes);
    return avoidRoundingProblem((cptRealtimeMinutes) * 50);
  }, [cptRealtimeMinutes]);

  const totalCredits = useMemo(() => {
    return mapsCredits + realtimeCredits;
  }, [mapsCredits, realtimeCredits]);

  return (
    <div className="fluid-container mt-12">
      <div className="my-12 flex justify-center">
        <div className="grid grid-cols-3 gap-0 last:text-blue-700">
          <div>
            <h2 className="m-3 -ml-6 text-2xl font-bold text-blue-800">
              Credits
            </h2>
          </div>
          <div></div>
          <div></div>
          <div className="p-3 pl-2">
            <h3 className="text-base font-bold text-blue-700">Maps</h3>
          </div>
          <div className="p-3 pr-6">
            <h3>
              <span
                className="text-lg font-bold text-blue-800"
                data-cy="nb-requests"
              >
                {cptTileRequests}
              </span>{" "}
              requests
            </h3>
          </div>
          <div className="p-3 pr-2 text-right">
            <span
              className=" text-lg font-bold text-blue-800"
              data-cy="nb-credits-maps"
            >
              {mapsCredits}
            </span>
            /1000 credits
          </div>
          <div className="p-3 pl-2">
            <h3 className="text-base font-bold text-blue-700">Realtime</h3>
          </div>
          <div className="p-3 pr-6">
            <span
              className="text-lg font-bold text-blue-800"
              data-cy="nb-minutes"
            >
              {cptRealtimeMinutes}
            </span>{" "}
            minutes
          </div>
          <div className="p-3 pr-2 text-right">
            <span
              className="text-lg font-bold text-blue-800"
              data-cy="nb-credits-realtime"
            >
              {realtimeCredits}
            </span>
            /1000 credits
          </div>
          <div className="border-t-2 border-blue-700 p-3 pl-2">
            <h3 className="text-base font-bold text-blue-700">Total</h3>
          </div>
          <div className="border-t-2 border-blue-700 p-3 pr-6"></div>
          <div className="border-t-2 border-blue-700 p-3 pr-2 text-right">
            <span
              className="text-lg font-bold text-blue-800"
              data-cy="nb-credits-total"
            >
              {totalCredits}
            </span>
            /1000 credits
          </div>
        </div>
      </div>
      <div className="my-12 flex justify-center">
        <div
          ref={(elt) => elt && setContainer(elt)}
          style={{
            width,
            height,
          }}
        ></div>
      </div>
      <div className="my-12 flex justify-center">
        <div>
          <div className="my-6 flex items-center pt-2">
            <InputLabel appendClassName="w-20 -ml-16 text-right">
              Map size
            </InputLabel>
            <Input
              type="number"
              value={width}
              onKeyPress={(evt) => {
                if (evt.key === "Enter") {
                  verifySize();
                }
              }}
              onBlur={() => verifySize()}
              onChange={(evt) =>
                setWidth(parseInt(evt.currentTarget?.value, 10) || 0)
              }
              step="10"
              min="100"
              max={maxWidth}
            ></Input>
            <InputLabel>x</InputLabel>
            <Input
              type="number"
              value={height}
              onKeyPress={(evt) => {
                if (evt.key === "Enter") {
                  verifySize();
                }
              }}
              onBlur={() => verifySize()}
              onChange={(evt) =>
                setHeight(parseInt(evt.currentTarget?.value, 10) || 0)
              }
              step="10"
              min="100"
              max={10000}
            ></Input>
          </div>
          <div className="my-6 flex items-center">
            <InputLabel appendClassName="w-20 text-right">
              Vector tiles
            </InputLabel>
            <Switch
              checked={useRaster}
              onChange={setUseRaster}
              data-cy="switch-raster"
            ></Switch>
            <InputLabel appendClassName="w-20">Raster tiles</InputLabel>
          </div>
          <div className="my-6 flex items-center">
            <InputLabel appendClassName="w-20 ml-6 text-right">
              Realtime
            </InputLabel>
            <Input
              type="checkbox"
              checked={useRealtime}
              onChange={(evt) => {
                setUseRealtime(evt.currentTarget?.checked);
              }}
              step="10"
              appendClassName={"mt-0"}
              data-cy="checkbox-realtime"
            ></Input>
          </div>
        </div>
      </div>
    </div>
  );
}
