import { useEffect, useState } from "react";

let globalKey: string;

async function getKey(setKey: (key: string) => void) {
  if (!globalKey) {
    await fetch("https://backend.developer.geops.io/publickey")
      .then((response) => response.json())
      .then((data) => {
        globalKey = data.key;
      });
  }
  if (typeof globalKey === "string") setKey(globalKey);
}

export default function useDemoKey() {
  const [key, setKey] = useState<string>();

  useEffect(() => {
    getKey(setKey);
  }, []);

  return key;
}
