import Table from "./Table";

const plansPrices = [
  { plan: 'Free', credits: '100', price: '- €', additionalCredits: '- €' },
  { plan: 'Basic', credits: '1.000', price: '140 €', additionalCredits: '0,12 €' },
  { plan: 'Standard', credits: '10.000', price: '470 €', additionalCredits: '0,11 €' },
  { plan: 'Large', credits: '50.000', price: '900 €', additionalCredits: '0,10 €' },
  { plan: 'Xlarge', credits: '150.000', price: '2.400 €', additionalCredits: '0,09 €' },
  {
    plan: 'Custom',
    credits: '300.000',
    price: <span>3.900 €<br /><sub>or on request</sub></span>,
    additionalCredits: <span>0,07 €<br /><sub>or on request</sub></span>
  },
]

type TableRowProps = {
  plan: string | React.ReactNode,
  credits: string | React.ReactNode,
  price: string | React.ReactNode,
  additionalCredits: string | React.ReactNode,
}

function TableRow({ plan, credits, price, additionalCredits }: TableRowProps) {
  return (
    <Table.Row>
      {[plan, credits, price, additionalCredits].map((value, index) => {
        return (
          <Table.Cell
            key={`${value}-${index}`}
            className={`max-w-0 py-4 px-3 align-top text-sm font-bold text-gray-500 sm:w-auto sm:max-w-none${/(1|2|3)/.test(index.toString()) ? ' text-right' : ''}`}
          >
            {value}
          </Table.Cell>
        )
      })}    
    </Table.Row>
  )
}

export default function PricingPricesTable() {
  return (
    <div className="mt-12 space-y-8 lg:mt-20 not-prose">
      <Table data-cy="pricing-prices-table">
        <Table.Head>
          <Table.HeadCell>Plan</Table.HeadCell>
          <Table.HeadCell className="text-right">Credits</Table.HeadCell>
          <Table.HeadCell className="text-right">Price</Table.HeadCell>
          <Table.HeadCell className="text-right">Additional <br />Credits Each</Table.HeadCell>
        </Table.Head>
        <Table.Body>
          {plansPrices.map((item, index) => {
            return (
              <TableRow
                key={`${item}-${index}`}
                plan={item.plan}
                credits={item.credits}
                price={item.price}
                additionalCredits={item.additionalCredits}
              />
            )
          })}
        </Table.Body>
      </Table>
    </div>
  );
}
